<template>
  <section class="settings">
    <div class="settings__programmer">
      <p class="settings__programmer--title">Cronograma de encuestas</p>
      <div class="settings__programmer--timer">
        <div class="settings__programmer--seccion">
          <div class="settings__programmer--calendar">
            <iconic name="calendar"></iconic>
            <p>Fecha inicial</p>
          </div>
          <div>
            <input type="date" name="dateInit" class="settings__programmer--date" />
          </div>
        </div>
        <div class="settings__programmer--seccion">
          <div class="settings__programmer--clock">
            <iconic name="clock"></iconic>
            <p>Hora inicio</p>
          </div>
          <div>
            <select v-model="selectedHourInit" @click="showHours" class="settings__programmer--time">
              <option value="">Selecionar hora</option>
              <option v-for="hour in hours" :key="hour">{{ hour }}</option>
            </select>
          </div>
        </div>
        <div class="settings__programmer--seccion">
          <div class="settings__programmer--calendar">
            <iconic name="calendar"></iconic>
            <p>Fecha final</p>
          </div>
          <div>
            <input type="date" name="dateEnd" class="settings__programmer--date" />
          </div>
        </div>
        <div class="settings__programmer--seccion">
          <div class="settings__programmer--clock">
            <iconic name="clock"></iconic>
            <p>Hora final</p>
          </div>
          <div>
            <select v-model="selectedHour" @click="showHours" class="settings__programmer--time">
              <option value="">Seleccionar hora</option>
              <option v-for="hour in hours" :key="hour">{{ hour }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="settings__politics">
      <p class="settings__politics--title">Politicas</p>
      <div class="settings__politics--item">
        <p class="settings__politics--text">Publicar inmediatamente los votos de las personas</p>
        <Toggle class="settings__politics--toggle" />
      </div>
      <div class="settings__politics--item">
        <p class="settings__politics--text">Publicar inmediatamente el numero de votos</p>
        <Toggle class="settings__politics--toggle" />
      </div>
    </div>
    <div class="settings__permissions">
      <p class="settings__permissions--title">Permisos</p>
      <div class="settings__permissions--cities">
        <p class="settings__permissions--names">Ciudades</p>
        <div class="settings__permissions--group">
          <div v-for="(city, index) in cities" :key="index" class="settings__permissions--check">
            <input type="checkbox" :id="`cities-${index}`" :name="`cities-${city.name}`" :value="city.name" :checked="true" @change="check($event, 'cities', city._id)" />
            <label :for="`cities-${index}`">{{ city.name }}</label>
          </div>
        </div>
      </div>
      <div class="settings__permissions--offices">
        <p class="settings__permissions--names">Sedes</p>
        <div class="settings__permissions--group">
          <div v-for="(office, index) in officesData" :key="index" class="settings__permissions--check">
            <input type="checkbox" :id="`office-${index}`" :name="`office-${office.officeName}`" :value="office.officeName" :checked="true" @change="check($event, 'offices', office._id)" />
            <label :for="`office-${index}`">{{ office.officeName }}</label>
          </div>
        </div>
      </div>
      <div class="settings__permissions--users">
        <p class="settings__permissions--names">Usuarios</p>
        <div class="settings__permissions--group">
          <div v-for="(role, index) in roles" :key="index" class="settings__permissions--check">
            <input type="checkbox" :id="`users-${index}`" :name="`users-${index}`" :value="index" :checked="true" @change="check($event, 'roles', role)" />
            <label :for="`users-${index}`" capitalize>{{ role }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="create__btns">
      <button type="button" class="create__btns--cancel" @click="backViewPolls">Cancelar</button>
      <button type="button" class="create__btns--save" @click="savePolicesPolls">Guardar</button>
    </div>
  </section>
</template>
<script>
  import axios from "@/api/axios";
  export default {
    name: "settings",
    components: {
      Toggle: () => import("@/components/input/ToggleInput.vue"),
    },
    props: ["dataId"],
    data() {
      return {
        selectedHourInit: "",
        selectedHour: "",
        hours: [],
        horasMostradas: "",
        cities: [],
        officesData: [],
        roles: this.$global.dictionary.roles_es_auto,
        policiesView: {
          cities: [],
          offices: [],
          areas: [],
          users: [],
          roles: [],
        },
      };
    },
    methods: {
      check(event, tag, id) {
        const isChecked = event.srcElement.checked ? true : false;
        if (isChecked) {
          !this.policiesView[tag].includes(id) ? this.policiesView[tag].push(id) : null;
        } else {
          let index = this.policiesView[tag].indexOf(id);
          if (index !== -1) {
            this.policiesView[tag].splice(index, 1);
          }
        }
      },
      async savePolicesPolls() {
        let resp = await this.$store.dispatch("polls/sendPolicies", { id: this.dataId, policiesView: this.policiesView });
        if (resp.success) {
          this.$alerts.dataSendSuccess({ message: "Encuesta actualizada" });
        } else {
          this.$alerts.dataSendError({ message: "Error" });
        }
      },
      backViewPolls() {
        this.$router.push({ name: "polls" });
      },
      async fetchRoles() {
        for (let key in this.roles) {
          if (this.roles.hasOwnProperty(key)) {
            this.policiesView.roles.push(this.roles[key]);
          }
        }
      },
      async fetchCities() {
        try {
          let response = await axios.get("/cities/");
          let data = response.data;
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            this.policiesView.cities.push(element._id);
            this.cities.push({ name: element.cityName, _id: element._id });
          }
        } catch (error) {
          console.error("Error al obtener oficinas:", error);
        }
      },
      async fetchOffices() {
        try {
          const response = await axios.get("/offices/");
          this.officesData = response.data;
          for (let index = 0; index < response.data.length; index++) {
            const element = response.data[index];
            this.policiesView.offices.push(element._id);
          }
        } catch (error) {
          console.error("Error al obtener oficinas:", error);
        }
      },
      padZero(value) {
        return value < 10 ? "0" + value : value;
      },
      showHours() {
        this.hours = [];
        this.horasMostradas = "";
        let horaActual = "00:00";

        for (let i = 0; i < 24; i++) {
          if (this.selectedHour && horaActual > this.selectedHour) {
            break;
          }
          this.hours.push(horaActual);

          const [hh, mm] = horaActual.split(":");
          const minutos = parseInt(hh) * 60 + parseInt(mm);
          minutos += 60;
          const nuevaHora = new Date(0, 0, 0, Math.floor(minutos / 60), minutos % 60);
          horaActual = nuevaHora.toTimeString().substring(0, 5);
        }

        this.horasMostradas = "Horas mostradas: " + this.hours.join(", ");
      },
    },
    mounted() {
      this.fetchCities();
      this.fetchOffices();
      this.fetchRoles();
    },
  };
</script>
<style lang="scss">
  .settings {
    &__programmer {
      width: 100%;
      max-width: 1440px;
      height: 100%;
      margin: 1% 0;
      border-radius: 6px;
      background-color: #fff;
      padding: 15px 20px 20px 20px;
      border: 1px solid #00000040;
      box-shadow: 0px 1px 4px 0px #00000040;
      &--title {
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        line-height: 20px;
        letter-spacing: 0em;
        font-family: $primary-font;
      }
      &--timer {
        gap: 2%;
        display: flex;
        margin-top: 15px;
      }
      &--calendar,
      &--clock {
        display: flex;
        gap: 10px;
        margin-bottom: 10px;
      }
      &--date {
        border: 1px solid #00000040;
        border-radius: 6px;
        height: 35px;
        width: 220px;
        padding: 0 10px;
      }
      &--time {
        border: 1px solid #00000040;
        border-radius: 6px;
        height: 35px;
        width: 220px;
        padding: 0 10px;
      }
    }
    &__politics {
      width: 100%;
      max-width: 1440px;
      height: 100%;
      margin: 1% 0;
      border-radius: 6px;
      background-color: #fff;
      padding: 15px 20px 20px 20px;
      border: 1px solid #00000040;
      box-shadow: 0px 1px 4px 0px #00000040;
      &--title {
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        line-height: 20px;
        letter-spacing: 0em;
        font-family: $primary-font;
      }
      &--item {
        display: flex;
        margin: 10px 0;
        padding: 10px 0;
        justify-content: space-between;
        border-bottom: 1px solid #00000040;
      }
    }
    &__permissions {
      width: 100%;
      max-width: 1440px;
      height: 100%;
      margin: 1% 0;
      border-radius: 6px;
      background-color: #fff;
      padding: 15px 20px 20px 20px;
      border: 1px solid #00000040;
      box-shadow: 0px 1px 4px 0px #00000040;
      &--title {
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        line-height: 20px;
        letter-spacing: 0em;
        font-family: $primary-font;
      }
      &--cities {
        padding: 10px 0;
      }
      &--users {
        padding: 10px 0;
      }
      &--names {
        padding: 5px 0;
      }
      &--group {
        gap: 10px;
        display: flex;
        padding: 5px 0;
        flex-wrap: wrap;
        border-bottom: 1px solid #00000040;
      }
      &--check {
        display: flex;
        gap: 5px;
        margin-right: 15px;
      }
    }
  }
</style>
